<template>
  <div>
    <NuxtRouteAnnouncer />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
useHead({
  titleTemplate: (titleChunk) => titleChunk ? `${titleChunk} - Hikers Roundtable` : 'Hikers Roundtable',
  script: [
    {
      src: 'https://cdnjs.cloudflare.com/polyfill/v3/polyfill.js?version=4.8.0&features=Array.prototype.at%2Cdefault',
      async: true
    }
  ],
})
</script>