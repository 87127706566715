import { default as exampleUy0vkjzSvJMeta } from "/app/app/pages/admin/example.vue?macro=true";
import { default as indexvzSkyqRO0qMeta } from "/app/app/pages/admin/index.vue?macro=true";
import { default as loginlPhsUuqLocMeta } from "/app/app/pages/admin/login.vue?macro=true";
import { default as _91slug_93ojILsmMsq6Meta } from "/app/app/pages/events/[slug].vue?macro=true";
import { default as _91id_93kYi0PWymUMMeta } from "/app/app/pages/events/confirmation/[id].vue?macro=true";
import { default as indexM6mJRhIbwTMeta } from "/app/app/pages/index.vue?macro=true";
export default [
  {
    name: "admin-example___en",
    path: "/admin/example",
    component: () => import("/app/app/pages/admin/example.vue").then(m => m.default || m)
  },
  {
    name: "admin-example___ms",
    path: "/ms/admin/example",
    component: () => import("/app/app/pages/admin/example.vue").then(m => m.default || m)
  },
  {
    name: "admin-example___zh-CN",
    path: "/zh-CN/admin/example",
    component: () => import("/app/app/pages/admin/example.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/admin",
    component: () => import("/app/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___ms",
    path: "/ms/admin",
    component: () => import("/app/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___zh-CN",
    path: "/zh-CN/admin",
    component: () => import("/app/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-login___en",
    path: "/admin/login",
    component: () => import("/app/app/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: "admin-login___ms",
    path: "/ms/admin/login",
    component: () => import("/app/app/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: "admin-login___zh-CN",
    path: "/zh-CN/admin/login",
    component: () => import("/app/app/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: "events-slug___en",
    path: "/events/:slug()",
    component: () => import("/app/app/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events-slug___ms",
    path: "/ms/events/:slug()",
    component: () => import("/app/app/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events-slug___zh-CN",
    path: "/zh-CN/events/:slug()",
    component: () => import("/app/app/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events-confirmation-id___en",
    path: "/events/confirmation/:id()",
    component: () => import("/app/app/pages/events/confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: "events-confirmation-id___ms",
    path: "/ms/events/confirmation/:id()",
    component: () => import("/app/app/pages/events/confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: "events-confirmation-id___zh-CN",
    path: "/zh-CN/events/confirmation/:id()",
    component: () => import("/app/app/pages/events/confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ms",
    path: "/ms",
    component: () => import("/app/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh-CN",
    path: "/zh-CN",
    component: () => import("/app/app/pages/index.vue").then(m => m.default || m)
  }
]