export default defineNuxtRouteMiddleware(async (to) => {
  if (!to.path.startsWith('/admin')) return;
  if (to.path === '/admin/login') return;

  if (import.meta.server) {
    const event = useRequestEvent()
    if (event!.context.admin) {
      useState<any>('admin', () => event!.context.admin)
    } else {
      navigateTo('/admin/login')
    }
  } else {
    const admin = useState<any>('admin')
    try {
      if (!admin.value) {
        admin.value = await $fetch('/api/v1/admin/account/me')
      }
    } catch (error) {
      navigateTo('/admin/login')
    }
  }
})